




























import {computed, defineComponent, PropType, ref} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {VersionTaskState} from '@/tasks/types/VersionTaskState';

type Inputs = {
  configurations: {
    atom1: string | null;
    atom2: string | null;
    atom3: string | null;
    atom4: string | null;
    atom5: string | null;
    atom6: string | null;
    atom7: string | null;
    atom8: string | null;
    atom9: string | null;
    atom10: string | null;
  };
};

export default defineComponent({
  name: 'TorontoCHMB41HQuiz3Question3',
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<VersionTaskState>,
      required: true,
    },
  },
  setup(props) {
    const configurations = ['R', 'S'];
    const inputs = ref<Inputs>({
      configurations: {
        atom1: null,
        atom2: null,
        atom3: null,
        atom4: null,
        atom5: null,
        atom6: null,
        atom7: null,
        atom8: null,
        atom9: null,
        atom10: null,
      },
    });

    const version = computed(() => props.taskState.getValueByName('version')?.content?.value ?? 1);
    const image = computed(
      () => `/img/assignments/UofT/CHMB41H/quiz-3/question-3/q3q3v${version.value}.png`
    );

    return {
      inputs,
      configurations,
      version,
      image,
    };
  },
});
